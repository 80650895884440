<template>
	<div class="detail">
		<detail></detail>
	</div>
</template>

<script>
	import detail from "../components/Home/detail.vue"
	export default {
		name:'dt',
		components: {
			detail
		}
	}
</script>
<style>

</style>