<template>
  <div id="ntrance">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0,user-scalable=no"
    />
	<div class="showIndex">

	
		
			
				<div class="content" v-for="(item,index) in arr" @click="btnIntroduce(item.id)">
					<div class="conLeft">
						<img :src="imgUrl+item.logo" />
					</div>
					<div style="margin-left: 0.3125rem;width:70% ;">
						<div class="conRig">
							<div style="font-size: 0.75rem;font-weight: bold;margin-top: 0.125rem;">{{item.equity_name}}</div>
							<div style="font-size: 0.75rem;margin-left: 1.875rem;color: #666666;">></div>
						</div>
						<div style="display: flex;margin-top:1.25rem;">
							<div><img class="imgAddress" src="../../image/address.png" /></div>
							<div style="font-size: 0.625rem;margin-top: 0.25rem">{{item.address}}</div>
						</div>
					</div>
				</div>
				
			
			
	
	
	
	</div>

  </div>
</template>
<script>
	import {
		Dialog
	} from 'vant';
export default {
	
  name: "Entrance",
	
  data() {
    return {
		orderDetail:'',
		id:'',
		imgUrl:'',
		arr:[
		
	
		],
	};
  },
  components: {},
  methods: {
	  btnIntroduce(e)
	  {
		  this.$router.push({
		  	path: "/introduce?id="+e,
		  })  
	  }
  },
  created() {
   var imgUrl=this.$request.imgUrl()
   this.imgUrl=imgUrl
   this.id=this.$route.query.id
   this.$request.getMoreInfo({
     id: this.$route.query.id,
   }).then(res => { 
     this.arr=res.data
   	
   })
  },
  beforeCreate() {},
  mounted() {},
  destroyed() {},
};
</script>

<style scoped>
#ntrance {
  width: 100vw;
  height: 100vh;
  background:#f3f3f3;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  overflow: auto;
}

.showIndex
{
	width: 94%;
	margin-left: 3%;

}



.content
{
	display: flex;
	margin-top: 0.3125rem;
	
	align-items: center;
	width: 100%;
	height: 5rem;
	background-color: white;
	border-radius: 10px;
}
.conLeft
{
	/* display: flex; */
	/* width: 30%; */
	width: 23%;
	height: 4rem;
	margin-left: 0.5rem;
	/* border: 1px solid black; */
}
.conLeft img
{
width:100% ;
	height: 100%;
}
.conRig
{
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.imgAddress
{
	width: 0.5rem;
	height: 0.6875rem;
}
</style>
